import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "./notfound.module.css";

export default function PageNotFound({ className }) {
  const navigate = useNavigate();

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div className={`${styles.text1}`}>404</div>
      <div className={`text-20 ${styles.text2}`}>Oops! Page not found.</div>
      <p onClick={() => navigate("/")} className={`text-16 ${styles.button}`}>
        Back to Home
      </p>
    </div>
  );
}
