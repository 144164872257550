import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { FOOTER_MENU_LINKS, SOCIAL_MEDIA_LINKS } from "./constant";
import { ImageLazyLoader } from "@common/lazy-loaders/image";
import { AOS_ANIMATION } from "@common/aos-animation";

import Logo from "@images/logo/grptech-brand-logo.png";
import patternBgWeb from "./assets/pattern-bg-web.svg";
import patternBgMobile from "./assets/pattern-bg-mobile.svg";
import patternBgMapWeb from "./assets/pattern-bg-map-web.svg";
import patternBgMapMobile from "./assets/pattern-bg-map-mobile.svg";
import leftPatternAssets from "./assets/left-pattern-asset.svg";

import styles from "./footer.style.module.css";
import { GoogleMap } from "@components/google-map";

export const Footer = () => {
  const navigate = useNavigate();

  const allowHeadder = window.location.pathname !== "/contact-us";
  const allowMap = window.location.pathname === "/contact-us";

  return (
    <div className={styles.footerWrapper}>
      {allowHeadder && (
        <>
          <div className={`wrapper ${styles.headerWrapper}`}>
            <div className={`container ${styles.headerConatiner}`}>
              <h2 className={`title-32`}>Let's start growing your business</h2>
              <p className={`text-16`}>
                We look forward to working with you and contributing to your
                success.
              </p>
              <Link
                to="/contact-us"
                className={`text-16 ${styles.btnContainer}`}
              >
                Contact Us
              </Link>
            </div>
          </div>

          <img
            src={patternBgWeb}
            alt="pattern-bg"
            className={`${styles.patternBg} ${styles.web}`}
          />
          <img
            src={patternBgMobile}
            alt="pattern-bg"
            className={`${styles.patternBg} ${styles.mobile}`}
          />
          <img
            src={leftPatternAssets}
            alt="left-pattern-asset"
            className={styles.leftPatternAsset}
          />
        </>
      )}
      {allowMap && (
        <div className={`${styles.mapContainer}`}>
          <GoogleMap latitude="13.093554" longitude="80.17741" />
          <img
            src={patternBgMapWeb}
            alt="pattern-bg"
            className={`${styles.patternBg} ${styles.web}`}
          />
          <img
            src={patternBgMapMobile}
            alt="pattern-bg"
            className={`${styles.patternBg} ${styles.mobile}`}
          />
          <img
            src={leftPatternAssets}
            alt="left-pattern-asset"
            className={styles.leftPatternAsset}
          />
        </div>
      )}

      <div className={styles.footerContainer}>
        <div className={styles.logoLinkcontainer}>
          {/* ---------- logo section ----------- */}
          <div className={styles.logoContainerInfo}>
            <ImageLazyLoader
              src={Logo}
              className={styles.logo}
              data-aos={AOS_ANIMATION.FADE_UP.TYPE}
              data-aos-duration={AOS_ANIMATION.FADE_UP.DURATION}
            />
            <div
              className={`text-16 ${styles.companyName}`}
              data-aos={AOS_ANIMATION.FADE_UP.TYPE}
              data-aos-duration={AOS_ANIMATION.FADE_UP.DURATION}
            >
              We at GRP Tech committed to quality services to our valued
              customers.
            </div>

            <div className={styles.mediaIconContainer}>
              {SOCIAL_MEDIA_LINKS.map((item, index) => {
                return (
                  <a
                    href={item.path}
                    target="_blank"
                    key={index}
                    data-aos={AOS_ANIMATION.FADE_UP.TYPE}
                    data-aos-duration={AOS_ANIMATION.FADE_UP.DURATION}
                    rel="noreferrer"
                  >
                    {item.icon}
                  </a>
                );
              })}
            </div>
          </div>

          {/* ---------- link sections ---------- */}
          {FOOTER_MENU_LINKS.map((item, index) => {
            return (
              <div className={styles.menuLinksContainer} key={index}>
                <div className={`text-16 ${styles.title}`}>{item.title}</div>
                {item["links"].map((data, index) => {
                  return (
                    <div className={`${styles.linkInfoContainer}`}>
                      <div
                        className={`text-14 ${styles.linkInfo}`}
                        key={index}
                        onClick={() => data?.path && navigate(data.path)}
                        id={`sui-aos`}
                      >
                        {data.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        {/* --------- copy rights ---------- */}
        <div className={styles.copyRightsContainer}>
          <div className={`text-14 ${styles.leftInfo}`}>
            All Rights Reserved GRP Tech | Designed by{" "}
            <a
              className={`${styles.serviceProvider}`}
              href="https://www.behance.net/kavinpalanivelu"
              target="_blank"
              rel="noreferrer"
            >
              Kavin
            </a>
          </div>
          <div className={`text-14 ${styles.rightInfo}`}>
            <span>Powered by </span>
            <a
              className={`${styles.serviceProvider}`}
              href="https://skynoveau.in/"
              target="_blank"
              rel="noreferrer"
            >
              Skynoveau Technology
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
