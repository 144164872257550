import React from "react";
// import { ParallaxProvider } from "react-scroll-parallax";

// import { SmoothScrollbar } from "./smooth_scrollbar";

export const AnimationProvider = ({ children }) => {
  return (
    <>
      {/* <ParallaxProvider> */}
      {/* <SmoothScrollbar> */}
      {children}
      {/* </SmoothScrollbar> */}
      {/* </ParallaxProvider> */}
    </>
  );
};
