import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AOS from "aos";

import { Navbar } from "@components/navbar";
import { Whatsapp } from "@components/whatsapp";
import { useAosApply } from "./common/hooks";
import { Loader } from "@components/loader/variant-1";
import { Footer } from "@components/footer/variant-1";
import PageNotFound from "@components/page-not-found";
import { SuspenseLazyLoader } from "@common/lazy-loaders";
import { AnimationProvider } from "@common/animation-provider";

// Note : don't change the order
import "@common/root.css";

import "@common/font/font.css";
import "@common/font/text.css";
import "@common/font/title.css";

import "@common/global.css";
import "@common/global.override.css";

// --------- Lazy-loaded components ----------
const Home = SuspenseLazyLoader(React.lazy(() => import("@pages/home")));
const About = SuspenseLazyLoader(React.lazy(() => import("@pages/about")));
const Products = SuspenseLazyLoader(
  React.lazy(() => import("@pages/products"))
);
const Contact = SuspenseLazyLoader(React.lazy(() => import("@pages/contact")));
const Gallery = SuspenseLazyLoader(React.lazy(() => import("@pages/gallery")));
// -------------------------------------------

function App() {
  const location = useLocation();
  const [isLoader, setIsLoader] = useState(false);

  useAosApply(isLoader);

  useEffect(() => {
    setIsLoader(true);

    setTimeout(() => {
      setIsLoader(false);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    AOS.init({
      offset: 100,
    });
  }, []);

  return (
    <AnimationProvider>
      {isLoader && <Loader />}
      {!isLoader && (
        <React.Fragment>
          <Navbar />
          <Whatsapp />
          {/* ---------- routes ---------- */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/products" element={<Products />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>

          <Footer />
        </React.Fragment>
      )}
    </AnimationProvider>
  );
}

export default App;
