import { useEffect, useLayoutEffect, useState } from "react";
import { AOS_ANIMATION } from "@common/aos-animation";

export const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};

export const useAosApply = (isLoader) => {
  useLayoutEffect(() => {
    const elements = document.querySelectorAll("#sui-aos");
    console.log("elements", elements);
    elements.forEach((element) => {
      console.log("element", element);
      element.setAttribute("data-aos", AOS_ANIMATION.FADE_UP.TYPE);
      element.setAttribute("data-aos-duration", AOS_ANIMATION.FADE_UP.DURATION);
      element.removeAttribute("sui-aos");
    });
  }, [isLoader]);
};

export const useDomHeight = ({ type = "hover", selector }) => {
  const [domHeight, setDomHeight] = useState(0);
  let handleMouseEnter = () => {};
  let handleMouseLeave = () => {};
  let handleClick = () => {};

  if (type === "hover") {
    handleMouseEnter = (e) => {
      const selectedElement = e.currentTarget.querySelector(selector);
      if (selectedElement) {
        const fullHeight = selectedElement.scrollHeight;
        setDomHeight(fullHeight);
      }
    };
    handleMouseLeave = () => {
      setDomHeight(0);
    };
  }

  if (type === "click") {
    handleClick = (e) => {
      const selectedElement = e.currentTarget.querySelector(selector);
      if (selectedElement) {
        const fullHeight = selectedElement.scrollHeight;
        setDomHeight(fullHeight);
      }
    };
  }

  return { domHeight, handleMouseEnter, handleMouseLeave, handleClick };
};
