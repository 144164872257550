import { CgFacebook } from "react-icons/cg";
import { AiFillInstagram } from "react-icons/ai";
import { BiLogoLinkedin } from "react-icons/bi";

export const SOCIAL_MEDIA_LINKS = [
  {
    icon: <CgFacebook />,
    path: "https://www.facebook.com/profile.php?id=61567458933852",
  },
  {
    icon: <AiFillInstagram />,
    path: "https://www.instagram.com/_grptech_?igsh=Z3V3cTlveHpyeWI3",
  },
  {
    icon: <BiLogoLinkedin />,
    path: "https://www.linkedin.com/in/grp-tech-8a5b58334",
  },
];

export const FOOTER_MENU_LINKS = [
  {
    title: "QUICK LINKS",
    links: [
      { name: "Home", path: "/" },
      { name: "About", path: "/about" },
      { name: "Products", path: "/products" },
      { name: "Gallery", path: "/gallery" },
      { name: "Contact us", path: "/contact-us" },
    ],
  },
  {
    title: "OUR PRODUCTS",
    links: [
      { name: "Machine Tool components" },
      { name: "Precision Machined components" },
      { name: "Machine Tool Sub’s Assembly" },
    ],
  },
  {
    title: "CONTACT",
    links: [
      { name: "91- 9840027255" },
      { name: "grptech2015@gmail.com" },
      { name: "grptech2018@gmail.com" },
      {
        name: "7/16-A, gandhi Street, Mathiyazhagan Nagar, PadiChennai-600050",
      },
    ],
  },
];
