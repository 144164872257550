import React from "react";

import styles from "./loader.style.module.css";

export const Loader = ({ className }) => {
  return (
    <div className={`${styles.loaderContainer} ${className}`}>
      <div className={styles.circle}></div>
      <p>Loading</p>
    </div>
  );
};
