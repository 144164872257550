import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { DropDown } from "./dropdown";
import { HamburgerMenu } from "@components/navbar/hamburger/variant-1/inxdex";
import { EmptySpace } from "@components/navbar/empty-space";

import "./hamburger.override.css";
import styles from "./navbar.style.module.css";

import Logo from "@images/logo/grptech-logo.png";

export const NavbarMobile = ({ className }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [navHeight, setNavHeight] = useState("60px");
  const [navBgColor, setNavBgColor] = useState("white");

  const listenScrollEvent = () => {
    if (window.scrollY < 10) {
      setNavBgColor("white");
      setNavHeight("60px");
    } else {
      setNavBgColor("white");
      setNavHeight("70px");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <React.Fragment>
      {/* header */}
      <div
        className={`wrapper ${styles.headerWrapper} ${className}`}
        style={{
          height: navHeight,
          "--header-bg-color": navBgColor,
          transition: "all 0.5s",
        }}
      >
        <div className={`container ${styles.headerContainer}`}>
          <div className={styles.leftInfo}>
            <Link to="/" toggle={() => setIsMenuOpen(false)}>
              <img src={Logo} alt="skyui-logo" />
            </Link>
          </div>
          <HamburgerMenu
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        </div>
      </div>

      {/* dropdown */}
      <div
        className={`${styles.dropdownContainer} ${
          isMenuOpen && styles.showDropdown
        }`}
      >
        <DropDown setIsMenuOpen={setIsMenuOpen} />
      </div>

      {/* empty space */}
      <EmptySpace bgColor={navBgColor} height={navHeight} />
    </React.Fragment>
  );
};
